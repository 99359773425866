// EventDetails.js

import React from 'react';
import moment from 'moment';

const EventDetails = ({ eventData, moduleName, setEditEventOpen }) => (
  <div className="event__details__summary">
    <div className="event__date">
      <div>
        <span className="event__day">
          {moment(eventData.startDate).format("MMMM DD")}
        </span>
        <span className="event__hour">
          {moment(eventData.startDate).format("hh:mm A")} :{" "}
          {moment(eventData.endDate).format("hh:mm A")}{" "}
        </span>
      </div>
      <div>
        <span
          className="event__edit"
          onClick={() => setEditEventOpen(true)}
        >
          <img
            src={"/assets/images/edit_icon.svg"}
            className="event__manager__edit__icon"
            alt="edit icon"
          />
        </span>
      </div>
    </div>
    <div className="event__summary">
      {eventData.description && (
        <div>Description: {eventData.description}</div>
      )}
      {eventData.location && <div>Location: {eventData.location}</div>}
      {moduleName && <div>Module: {moduleName}</div>}
    </div>
  </div>
);

export default EventDetails;
