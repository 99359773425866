import React, { Suspense, lazy, useContext } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

import * as ROUTES from "../../constants/routes";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { AuthUserContext } from "../Session";

import "./index.css";

// Auth HOC
import { withAuthentication } from "../Session";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Screens
import Navigation from "../Navigation";
import QuestAPI from "../QuestAPI";
import Dashboard from "../HomePage/homeComponents/Dashboard";
import Notification from "../Notification";
import LandingPage from "../Landing";
import CompletionCertificates from "../Classroom/components/CompletionCertificates";
import SignInPage from "../SignIn";
import Calendar from "../Calendar";
import ViewLessonPage from "../ViewLessonPage";
import ViewEventPage from "../ManageEvents/components/AttendancePage";
import ConfirmedEventPage from "../ManageEvents/components/ConfirmedEventPage";
import OfflineChecker from "../OfflineChecker";
import CourseTaskList from "../CourseTaskList";
import ModuleReviews from "../Reviews";
import DiversionLandingUpdate from "../DiversionLandingPage";
import EIGhyst from "../EIGhyst";
import EIResultsPage from "../EQResultsPage";
import BudgetingGame from "../ViewSection/sectionComponents/Snowball/BudgetingGame";
import FinanceLessons from "../ViewSection/sectionComponents/Snowball/NewFinanceLesson";
import CreditSimulator from "../ViewSection/sectionComponents/Snowball/CreditSimulator";
import LSIAssessment from "../LSIAssessment";
import UserSettings from "../Settings";
import Validate from "../Validate";
import ClassroomChats from "../ClassroomChats";

const Classroom = lazy(() => import("../Classroom"));

const AccountPage = lazy(() => import("../Account"));
const AdminPage = lazy(() => import("../Admin"));
const LSIPage = lazy(() => import("../LSI"));
const AddLessonPage = lazy(() => import("../AddLesson"));
const EditLessonPage = lazy(() => import("../EditLesson"));
const ViewClassroom = lazy(() => import("../ViewClassroom"));
const ChatScreen = lazy(() => import("../ChatScreen"));
const ViewUserPage = lazy(() => import("../ViewUserPage"));
const LessonsAnalytics = lazy(() => import("../LessonsAnalytics"));
const PrivacyPolicy = React.lazy(() => import("../PrivacyPolicy"));
const FinalAssessment = React.lazy(() => import("../FinalAssessment"));
const EditLSI = React.lazy(() => import("../EditLSI"));
const Issues = React.lazy(() => import("../Issues"));

const App = () => {
  const authUser = useContext(AuthUserContext);

  return (
    <>
      <ToastContainer />

      <Router>
        <div
          className={
            authUser ? "mainAppAuthContainer" : "mainAppLanfingContainer"
          }
        >
          <div style={{ display: "flex" }}>
            <Navigation />
          </div>
          <div
            className="app__container"
            style={{
              // padding: authUser ? 0 : 0,
              minHeight: "100vh",
              width: "100%",
            }}
          >
            <Notification />
            <OfflineChecker />
            <Suspense
              fallback={<div className="startloading__container">Loading</div>}
            >
              <Route exact path={ROUTES.LANDING} component={LandingPage} />
              <Route path={ROUTES.HOME} component={Dashboard} />
              <Route path={ROUTES.SIGN_IN} component={SignInPage} />
              <Route
                path={ROUTES.VIEW_LESSON_PAGE}
                component={ViewLessonPage}
              />
              <Route path={ROUTES.VIEW_EVENT_PAGE} component={ViewEventPage} />

              <Route
                path={ROUTES.DIVERSION}
                component={DiversionLandingUpdate}
              />

              <Route
                path={ROUTES.VIEW_EVENT_CONFIRMATION_PAGE}
                component={ConfirmedEventPage}
              />
              <Route path={ROUTES.ACCOUNT} component={AccountPage} />
              <Route path={ROUTES.ADMIN} component={AdminPage} />
              <Route path={ROUTES.LSI} component={LSIPage} />
              <Route path={ROUTES.ADD_LESSON_PAGE} component={AddLessonPage} />
              <Route
                path={ROUTES.EDIT_LESSON_PAGE}
                component={EditLessonPage}
              />
              <Route path={ROUTES.VIEW_CLASSROOM} component={ViewClassroom} />
              <Route path={ROUTES.CHAT_SCREEN} component={ChatScreen} />
              <Route path={ROUTES.VIEW_USER_PAGE} component={ViewUserPage} />
              <Route path={ROUTES.PRIVACY_POLICY} component={PrivacyPolicy} />
              <Route path={ROUTES.CALENDAR} component={Calendar} />
              <Route
                path={ROUTES.LESSONS_ANALYTICS}
                component={LessonsAnalytics}
              />
              <Route path={ROUTES.QUEST_EQ_TEST} component={QuestAPI} />
              <Route
                path={ROUTES.FINAL_ASSESSMENT}
                component={FinalAssessment}
              />
              <Route path={ROUTES.CLASSROOM} component={Classroom} />
              <Route path={ROUTES.ISSUES} component={Issues} />
              <Route path={ROUTES.ANALYTICS} component={LessonsAnalytics} />
              <Route path={ROUTES.TASKLIST} component={CourseTaskList} />
              <Route path={ROUTES.SUPPORT} component={Support} />
              <Route path={ROUTES.EIGYST} component={EIGhyst} />
              <Route path={ROUTES.EIResults} component={EIResultsPage} />
              <Route path={ROUTES.BudgetingGame} component={BudgetingGame} />
              <Route path={ROUTES.FinanceLessons} component={FinanceLessons} />
              <Route path={ROUTES.LSI_ASSESSMENT} component={LSIAssessment} />
              <Route path={ROUTES.EDIT_LSI} component={EditLSI} />
              <Route path={ROUTES.USER_SETTINGS} component={UserSettings} />
              <Route path={ROUTES.VALIDATE} component={Validate} />
              <Route path={ROUTES.REVIEWS} component={ModuleReviews} />
              <Route
                path={ROUTES.CREDIT_SIMULATOR}
                component={CreditSimulator}
              />
              <Route
                path={ROUTES.CERFITICATES}
                component={CompletionCertificates}
              />
              <Route path={ROUTES.CLASSROOM_CHATS} component={ClassroomChats} />
            </Suspense>
          </div>
        </div>
      </Router>
    </>
  );
};

const Support = () => {
  return (
    <div
      style={{
        flex: 1,
        justifyContent: "center",
        textAlign: "center",
        marginTop: 30,
        fontWeight: "bold",
      }}
    >
      Please Reach out to us at ignacio@stoprecidivism.org for support
    </div>
  );
};

export default withAuthentication(App);
