import React from "react";
import "./Testimonials.css";

export const Testimonial = ({ name, text, stars }) => {
  const starIcons = [...Array(stars)].map((_, index) => (
    <img key={index} className="star-icon" alt="" src="/star-icon.svg" />
  ));

  return (
    <div className="testimonial-card1">
      <div className="testimonial-info">
        <div className="client-info">
          <div className="person-details1">
            <div className="john-doe1">"{text}"</div>
            <div className="vice-president1">Program Participant</div>
          </div>
        </div>
      </div>
      <div className="star-rating">{starIcons}</div>
    </div>
  );
};

export const Testimonials = () => {
  // Group feedback objects into rows
  const rows = [
    feedback.slice(0, 3), // First row
    feedback.slice(3, 6), // Second row
    feedback.slice(6, 9), // Third row
    // Add more rows as needed
  ];

  return (
    <div className="testimonials">
      <div className="headline-subhead2">
        <div className="reviews">Participant Reviews</div>
        <div className="see-what-others-container">
          <span>{`See what our graduates are saying about the program `}</span>
        </div>
      </div>
      {rows.map((row, rowIndex) => (
        <div className="cards-row1" key={rowIndex}>
          {row.map((item, index) => (
            <Testimonial
              key={index}
              name={item.name}
              text={item.text}
              stars={item.stars}
            />
          ))}
        </div>
      ))}
    </div>
  );
};

const feedback = [
  {
    name: "Program Participant",
    text: "Magnificent, Life changing, Going to miss it for sure.",
    stars: 5,
  },
  {
    name: "Program Participant",
    text: "My experience with Red was great from getting to meet everybody from staff and mentors and other participants in the program as well. RED has helped me become a better version of myself.",
    stars: 5,
  },
  {
    name: "Program Participant",
    text: "I'm extremely impressed with everything I've experienced in the RED program.",
    stars: 5,
  },
  {
    name: "Program Participant",
    text: "It was amazing and truly a blessing.",
    stars: 5,
  },
  {
    name: "Program Participant",
    text: "Life changing and saving experience. This program has truly turned my life around.",
    stars: 5,
  },
  {
    name: "Program Participant",
    text: "Absolutely incredible and life changing",
    stars: 5,
  },
  {
    name: "Program Participant",
    text: "Eventful and Breathtaking , Honestly didn't expect the warmth and hospitality.",
    stars: 5,
  },
  {
    name: "Program Participant",
    text: "Life changing and eye opening. Education that all citizens should obtain.",
    stars: 5,
  },
  // Add more feedback objects as needed
];

export default Testimonials;
