import React, { useState, useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import { editGeneralCalendarEvent } from "src/api/googleCalendar";
import { handleGoogleCalendarEvents } from "src/store/calendarEvents/calendarEventsActions";
import { openNotification } from "src/utils/notifications";
import moment from "moment";
import * as ROLES from "../../../constants/roles";
import { CheckboxRed } from "./CheckboxRed";
import { withFirebase } from "../../Firebase";
import { compose } from "recompose";
import {
  AuthUserContext,
  withAuthentication,
  withAuthorization,
} from "../../Session";
import "src/components/HomePage/homeComponents/Dashboard/components/MentorContent/index.css";

const EditEventModal = ({
  firebase,
  setCreatePrivateEventOpen,
  createPrivateEventOpen,
  eventName,
  eventDescription,
  eventLocation,
  googleCalendarEventId,
  calendarId,
  eventStartDate,
  eventEndDate,
  fetchEventData,
  fetchCalendar,
  eventId,
  classId,
  eventModule,
  classroom,
}) => {
  const [date, setDate] = useState(moment(eventStartDate).format("YYYY-MM-DD"));
  const [notifyUsers, setNotifyUsers] = useState(false);
  const [time, setTime] = useState(moment(eventStartDate).format("HH:mm"));
  const [endTime, setEndTime] = useState(moment(eventEndDate).format("HH:mm"));
  const authUser = useContext(AuthUserContext);
  const [eventObjectId, setEventObjectId] = useState(eventId);
  const [modules, setModules] = useState();
  const [loading, setLoading] = useState(false);
  const [eventDetails, setEventDetails] = useState({
    summary: eventName,
    description: eventDescription,
    location: eventLocation,
    module: eventModule,
  });

  const { summary, description, location, module } = eventDetails;

  const dispatch = useDispatch();

  useEffect(() => {
    if (!eventId) {
      fetchEventFromCalendar();
    } else {
      fetchClassRoomData(classId);
    }
  }, []);

  const fetchEventFromCalendar = async () => {
    await firebase.getEventData(eventId).once("value", (snapshot) => {
      let responseData = snapshot.val();
      responseData && setEventObjectId(responseData);
      let module = responseData.module;
      let classId = responseData.classId;
      setEventDetails({
        ...eventDetails,
        module: module,
      });
      fetchClassRoomData(classId);
    });
  };

  const fetchClassRoomData = async (classId) => {
    firebase
      .classrooms()
      .orderByChild("className")
      .equalTo(classId)
      .once("value", (snapshot) => {
        const classroomData = snapshot.val();
        console.log("ViewClassroom 43 | class data", classroomData);
        if (classroomData) {
          getLessonsForProgress(classroomData[Object.keys(classroomData)]);
        }
      });
  };

  const getLessonsForProgress = async (classroomData) => {
    let modulesAvailable = classroomData.settings.modules;

    if (modulesAvailable) {
      firebase.getModulesOverview().once("value", (modules) => {
        let modulesInClass = Object.values(modules.val()).filter(
          (moduleFromDb) => {
            return modulesAvailable[moduleFromDb.uid];
          }
        );
        setModules(modulesInClass);
      });
    }
  };

  const handleCreatePrivateEvent = async () => {
    let dateString = new Date(`${date}T${time}`);
    let endDateString = new Date(`${date}T${endTime}`);

    setLoading(true);

    try {
      if (eventId) {
        console.log("there is an event id ", eventId);
        await firebase
          .getEventData(eventId)
          .update({
            title: summary,
            startDate: dateString,
            endDate: endDateString,
            ...(module && { module: module }),
            ...(location && { location: location }),
            ...(description && { description: description }),
          })
          .then(() => {
            if (eventId) {
              fetchEventData(eventId);
            } else {
              fetchCalendar();
            }
          })
          .catch((err) => {
            console.log("error updating event", err);
          });
      } else {
        fetchCalendar();
      }

      setLoading(false);
      setCreatePrivateEventOpen(false);
      openNotification("Success!", "Event edited successfully!");
      dispatch(handleGoogleCalendarEvents(calendarId));
    } catch (error) {
      throw new Error("Error editing Event", error);
    }
  };

  const detailsIncomplete = () => {
    return !summary || !date || !time || !endTime;
  };

  return (
    <div className="createprivateevent__modal">
      <div className="createprivateevent__container">
        <div className="mentorprivateeventinfo__header">Edit event</div>
        <div className="mentorprivateeventbody__container">
          <div className="mentorprivateeventinfo__container">
            <div className="mentorprivateevent__title">Event Name</div>
            <div className="mentorprivateevent_input">
              <input
                placeholder="Call with John Smith"
                value={summary}
                defaultValue={eventName}
                onChange={(e) => {
                  setEventDetails({ ...eventDetails, summary: e.target.value });
                }}
              />
            </div>
          </div>
          <div className="mentorprivateeventinfo__container">
            <div className="mentorprivateevent__title">Module associated</div>
            <div className="mentorprivateevent_input">
              <select
                onChange={(e) => {
                  setEventDetails({
                    ...eventDetails,
                    module: e.target.value,
                  });
                }}
                name="modules"
                id="modules"
                value={module}
              >
                <option disabled selected value>
                  {" "}
                  -- select an option --{" "}
                </option>
                {modules &&
                  modules.map((e) => {
                    return <option value={e.uid}>{e.name}</option>;
                  })}
                <option value={undefined}>No module</option>
              </select>
            </div>
          </div>
          <div className="mentorprivateeventinfo__container">
            <div className="mentorprivateevent__title">Event Address</div>
            <div className="mentorprivateevent_input">
              <input
                placeholder="123 Some Road"
                value={location}
                defaultValue={eventLocation}
                onChange={(e) => {
                  setEventDetails({
                    ...eventDetails,
                    location: e.target.value,
                  });
                }}
              />
            </div>
          </div>
          <div className="mentorprivateeventinfo__container">
            <div className="mentorprivateevent__title">Event Description</div>
            <div className="mentorprivateevent_input">
              <input
                placeholder="Be ready 10 minutes early..."
                value={description}
                defaultValue={eventDescription}
                onChange={(e) => {
                  setEventDetails({
                    ...eventDetails,
                    description: e.target.value,
                  });
                }}
              />
            </div>
          </div>
          <div className="mentorprivateeventinfo__container">
            <div className="mentorprivateevent__title">Day and Time</div>
            <div className="mentorprivateevent_inputdate">
              <input
                type="date"
                value={date}
                onChange={(e) => {
                  setDate(e.target.value);
                }}
              />
              <input
                type="time"
                value={time}
                className="mentorprivateevent_inputdate"
                onChange={(e) => {
                  setTime(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="mentorprivateeventinfo__container">
            <div className="mentorprivateevent__title">End Time</div>
            <div className="mentorprivateevent_inputdate">
              <input
                type="time"
                value={endTime}
                className="mentorprivateevent_inputdate"
                onChange={(e) => {
                  console.log(
                    "CreatePrivateEventModal.js 64 | end time",
                    e.target.value
                  );
                  setEndTime(e.target.value);
                }}
              />
            </div>
          </div>

          <div className="mentorprivateeventbuttons__container">
            <button
              className="mentorprivateeventbuttons__button"
              onClick={() => setCreatePrivateEventOpen(!createPrivateEventOpen)}
            >
              Cancel
            </button>
            <button
              onClick={handleCreatePrivateEvent}
              disabled={detailsIncomplete()}
              className="mentorprivateeventbuttons__button"
            >
              {!loading ? "Save" : "Saving..."}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const condition = (authUser) => authUser && authUser.roles[ROLES.ADMIN];

export default compose(
  withAuthorization(condition),
  withAuthentication,
  withFirebase
)(EditEventModal);
