import React, { Component } from "react";
import "./index.css";
import { withFirebase } from "../Firebase";
import { withAuthentication } from "../Session";

import { compose } from "recompose";

import "react-quill/dist/quill.snow.css";

import { AddSectionContentButtons } from "./editSectionComponents/AddSectionContentButtons/AddSectionContentButtons";

import {
  Text,
  Video,
  SectionTitle,
  DragDropEdit,
  TextInput,
  Image,
  Likert,
  MultiDrapDropEdit,
  UserInput,
} from "./editSectionComponents";

import Carousel from "./editSectionComponents/Carousel";
import ChatGame from "./editSectionComponents/ChatGame";

import { Quiz } from "./editSectionComponents/Quiz";
import { DecisionTree } from "./editSectionComponents/DecisionTree";
import FileDownloadEdit from "./editSectionComponents/FileDownload";
import Table from "./editSectionComponents/Table";
import SnowballTutorial from "./editSectionComponents/SnowballTutorial";
import GEDPlatform from "./editSectionComponents/GEDPlatform";
import { TextSlateEdit } from "./editSectionComponents/TextSlate";
// import Image from "./editSectionComponents/Image";

class EditSectionForm extends Component {
  state = {
    videoFile: null,
    videoUrl: null,
    uploading: false,
    uploadStatus: 0,
  };

  manageVideo = (e, index) => {
    const { uploadFile } = this.props.firebase;
    console.log("EditSectionForm 37 | handling video upload");

    this.setState({ videoFile: e.target.files[0], uploading: true }, () => {
      uploadFile(`files/${this.state.videoFile.name}`)
        .put(this.state.videoFile)
        .on(
          "state_changed",
          (snapshot) => {
            var progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log("Upload is " + progress + "% done");
            this.setState({ uploadStatus: progress });
          },
          (err) => {
            console.log("EditSectionForm 47 | error ", err);
          },
          () => {
            console.log("EditSectionForm 43 | upload completed");
            this.setState({ uploading: false });
            uploadFile(`files/${this.state.videoFile.name}`)
              .getDownloadURL()
              .then((url) => {
                console.log("EditSectionForm 48 | url obtained");
                this.props.editSection(e, index, "file", null, url);
              })
              .catch((error) => {
                console.log(
                  "EditSectionForm 47 | upload error",
                  "ERROR",
                  error
                );
              });
          }
        );
    });
  };

  manageImage = (e, index) => {
    const { uploadFile } = this.props.firebase;
    const { editSection } = this.props;

    this.setState({ imageFile: e.target.files[0], uploading: true }, () => {
      uploadFile(`files/${this.state.imageFile.name}`)
        .put(this.state.imageFile)
        .then(() => {
          this.setState({ uploading: false });

          uploadFile(`files/${this.state.imageFile.name}`)
            .getDownloadURL()
            .then((url) => {
              editSection(e, index, "file", null, url);
            });
        })
        .catch((error) =>
          console.log("EditSectionForm.js 63 | error uploading file", error)
        );
    });
  };

  render() {
    const {
      onSectionSubmit,
      onSectionChange,
      editSection,
      removeContentAtIndex,
      addText,
      cancelEdit,
      addSlide,
      removeSlide,
      _addSituation,
      _removeSituation,
      _addActionResponseDanger,
      _removeActionResponseDanger,
      _moveSectionContentDown,
      _moveSectionContentUp,
      firebase,
    } = this.props;

    const { name, uid, content } = this.props.currentSectionEdit;
    const { uploading } = this.state;
    const { type, currentSectionNumber } = this.props;

    return (
      <div className="editor__container">
        <AddSectionContentButtons
          addSectionContent={addText}
          currentSectionNumber={currentSectionNumber}
        />

        <form
          className="edit-form-container"
          onSubmit={(e) => onSectionSubmit(e, uid)}
        >
          <button className="btn btn-success btn-sm btn-block update__button">
            Update
          </button>
          <br />
          <SectionTitle name={name} onChange={onSectionChange} />
          {content ? (
            content.map((item, index) => {
              switch (item.type) {
                case "text":
                  return (
                    <Text
                      key={index}
                      value={item.value}
                      editSection={editSection}
                      removeContentAtIndex={removeContentAtIndex}
                      index={index}
                      _moveSectionContentDown={_moveSectionContentDown}
                      _moveSectionContentUp={_moveSectionContentUp}
                      firebase={firebase}
                    />
                  );
                case "text-slate":
                  return (
                    <TextSlateEdit
                      key={index}
                      textValue={item.textValue}
                      editSection={editSection}
                      removeContentAtIndex={removeContentAtIndex}
                      index={index}
                      _moveSectionContentDown={_moveSectionContentDown}
                      _moveSectionContentUp={_moveSectionContentUp}
                      firebase={firebase}
                    />
                  );
                case "quiz":
                  return (
                    <Quiz
                      key={index}
                      index={index}
                      item={item}
                      type={type}
                      editSection={editSection}
                      removeContentAtIndex={removeContentAtIndex}
                      _moveSectionContentDown={_moveSectionContentDown}
                      _moveSectionContentUp={_moveSectionContentUp}
                      currentSectionNumber={currentSectionNumber}
                    />
                  );
                case "video":
                  return (
                    <Video
                      index={index}
                      item={item}
                      key={index}
                      editSection={editSection}
                      removeContentAtIndex={removeContentAtIndex}
                      manageVideo={this.manageVideo}
                      uploading={uploading}
                      uploadStatus={this.state.uploadStatus}
                      _moveSectionContentDown={_moveSectionContentDown}
                      _moveSectionContentUp={_moveSectionContentUp}
                    />
                  );
                case "image":
                  return (
                    <Image
                      index={index}
                      key={index}
                      item={item}
                      editSection={editSection}
                      uploading={uploading}
                      manageImage={this.manageImage}
                      removeContentAtIndex={removeContentAtIndex}
                      _moveSectionContentDown={_moveSectionContentDown}
                      _moveSectionContentUp={_moveSectionContentUp}
                    />
                  );
                case "crossword":
                  return (
                    <div key={index}>
                      Write the right words
                      {Object.entries(item.words).map((entry, wordIndex) => (
                        <label key={wordIndex}>
                          <p>{entry[0]}</p>
                          <input />
                        </label>
                      ))}
                    </div>
                  );
                case "drag-drop":
                  return (
                    <DragDropEdit
                      key={index}
                      index={index}
                      item={item}
                      editSection={editSection}
                      removeContentAtIndex={removeContentAtIndex}
                      _moveSectionContentDown={_moveSectionContentDown}
                      _moveSectionContentUp={this.props._moveSectionContentUp}
                    />
                  );
                case "likert":
                  return (
                    <Likert
                      key={index}
                      index={index}
                      item={item}
                      editSection={editSection}
                      removeContentAtIndex={removeContentAtIndex}
                      _moveSectionContentDown={_moveSectionContentDown}
                      _moveSectionContentUp={_moveSectionContentUp}
                    />
                  );
                case "text-input":
                  return (
                    <TextInput
                      key={index}
                      index={index}
                      item={item}
                      editSection={editSection}
                      removeContentAtIndex={removeContentAtIndex}
                      _moveSectionContentDown={_moveSectionContentDown}
                      _moveSectionContentUp={_moveSectionContentUp}
                    />
                  );
                case "carousel":
                  return (
                    <Carousel
                      key={index}
                      index={index}
                      item={item}
                      editSection={editSection}
                      removeContentAtIndex={removeContentAtIndex}
                      addSlide={addSlide}
                      removeSlide={removeSlide}
                      _moveSectionContentDown={_moveSectionContentDown}
                      _moveSectionContentUp={_moveSectionContentUp}
                    />
                  );
                case "chatGame":
                  return (
                    <ChatGame
                      key={index}
                      index={index}
                      item={item}
                      editSection={editSection}
                      removeContentAtIndex={removeContentAtIndex}
                      _addSituation={_addSituation}
                      _removeSituation={_removeSituation}
                      _addActionResponseDanger={_addActionResponseDanger}
                      _removeActionResponseDanger={_removeActionResponseDanger}
                      _moveSectionContentDown={_moveSectionContentDown}
                      _moveSectionContentUp={_moveSectionContentUp}
                    />
                  );
                case "decisionTree":
                  return (
                    <DecisionTree
                      key={index}
                      index={index}
                      item={item}
                      editSection={editSection}
                      removeContentAtIndex={removeContentAtIndex}
                      copiedTreeBranch={this.props.copiedTreeBranch}
                    />
                  );
                case "file":
                  return (
                    <FileDownloadEdit
                      key={index}
                      index={index}
                      _moveSectionContentDown={_moveSectionContentDown}
                      _moveSectionContentUp={_moveSectionContentUp}
                      item={item}
                      removeContentAtIndex={removeContentAtIndex}
                      editSection={editSection}
                    />
                  );
                case "table":
                  return (
                    <Table
                      key={index}
                      index={index}
                      item={item}
                      editSection={editSection}
                      _moveSectionContentDown={_moveSectionContentDown}
                      _moveSectionContentUp={_moveSectionContentUp}
                      removeContentAtIndex={removeContentAtIndex}
                    />
                  );
                case "multi-dragdrop":
                  return (
                    <MultiDrapDropEdit
                      key={index}
                      index={index}
                      item={item}
                      editSection={editSection}
                      _moveSectionContentDown={_moveSectionContentDown}
                      _moveSectionContentUp={_moveSectionContentUp}
                      removeContentAtIndex={removeContentAtIndex}
                    />
                  );

                case "snowball-spreadsheets":
                  return (
                    <SnowballTutorial
                      key={index}
                      index={index}
                      item={item}
                      editSection={editSection}
                      _moveSectionContentDown={_moveSectionContentDown}
                      _moveSectionContentUp={_moveSectionContentUp}
                      removeContentAtIndex={removeContentAtIndex}
                    />
                  );
                case "quest-api":
                  return <div>Quest API Questions</div>;
                case "ged-platform":
                  return <GEDPlatform />;
                case "user-input":
                  return (
                    <UserInput
                      key={index}
                      index={index}
                      item={item}
                      type={type}
                      editSection={editSection}
                      removeContentAtIndex={removeContentAtIndex}
                      currentSectionNumber={currentSectionNumber}
                    />
                  );
  

                default:
                  return null;
              }
            })
          ) : (
            <div>No content yet</div>
          )}
          <div className="update-edit-buttons">
            <button className="btn btn-success btn-block">Update</button>
            <br />
            <button
              className="btn btn-outline-danger btn-block"
              onClick={cancelEdit}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    );
  }
}

export default compose(withFirebase, withAuthentication)(EditSectionForm);
