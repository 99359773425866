import React from "react";
import FeaturesContainerRow from "./FeaturesContainerRow";
import "./FeaturesSection.css";

import { useDispatch } from "react-redux";
import { toggleSignupModal } from "../../../store/authUser/authUserActions";

const FeaturesSection = () => {
  const dispatch = useDispatch();

  return (
    <div className="features2">
      <div className="header1">
        <div className="header2">
          <div className="our-program">Our program</div>
          <div className="learn-more-about">
            Learn more about our unique capabilities.
          </div>
        </div>
        <div className="cardloco">
          <FeaturesContainerRow
            moduleDescription="Simple sign-up with county-specific QR code"
            classroomDescription={`Two classrooms: drug offenses & others`}
          />
          <FeaturesContainerRow
            moduleDescription="Modules: EQ, finance, substance disorder"
            classroomDescription="Completion results in a program certificate that enables case dismissal and record restriction."
          />
        </div>
      </div>
      <img
        className="graduation-ceremony-1-1-icon"
        alt=""
        src="/2022graduationceremony1-1@2x.png"
      />
    </div>
  );
};

export default FeaturesSection;
