/* eslint-disable */
import React, { Fragment } from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";

function SyllabusReport({ data, className, usersLength, documentTitle }) {
  return (
    <Document title={documentTitle} style={styles.documentStyle}>
      <Page size="A4" orientation="portrait">
        <View style={styles.page}>
          <Text
            style={{ fontFamily: "Poppins", fontWeight: "bold", fontSize: 18, paddingBottom: 16, }}
          >
            {className} Syllabus
          </Text>
          {data &&
            data.map((val, index) => {
              return (
                <View key={index} style={{ paddingBottom: 10 }}>
                  <Text style={{ fontWeight: 'bold', paddingBottom: 3 }}>{val.value}</Text>
                  <Text>{val.description}</Text>
                </View>
              );
            })}
        </View>
      </Page>
    </Document>
  );
}

const styles = StyleSheet.create({
  documentStyle: {
    fontFamily: "Poppins",
    backgroundColor: "#ffffff",
    color: "#000000",
  },

  page: {
    backgroundColor: "#ffffff",
    fontFamily: "Poppins",

    fontSize: 10,
    padding: "30px 40px",
    color: "#000000",
  },

  pdf_page_title: {
    fontWeight: "bold",
    fontSize: 18,
    fontFamily: "Poppins",
  },

  pdf_page_subtitle: {
    fontWeight: "bold",
    fontSize: 14,
  },

  pdf_normal_space: {
    marginTop: 10,
  },

  pdf_big_space: {
    marginTop: 20,
  },

  pdf_bold: {
    fontWeight: "bold",
    color: "#000000",
  },

  redTitle: {
    fontSize: 14,
    color: "#FFFFFF",
  },

  redContainer: {
    marginTop: 20,
    marginBottom: 20,
    backgroundColor: "#d94645",
    width: 220,
    height: 40,
    padding: 10,
    fontFamily: "Poppins",
  },
});

export default SyllabusReport;
