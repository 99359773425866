import React, { useEffect, useState } from "react";

import "./index.css";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { Event } from "./components/Event";
import { NoEvents } from "./components/NoEvents";
import ToggleSwitch from "../../../../../shared/ToggleSwitch";
import { NeedPermisions } from "../../../../../ViewSection/sectionComponents/SpreadsheetsTutorial/components/NeedPermisions";

const UpcomingEvents = ({ authUser, events }) => {
  const [activeSwitch, setActiveSwitch] = useState(true);
  let history = useHistory();

  const handleEventClick = (googleCalendarId) => {
    history.push(`/event/${googleCalendarId}`);
  };

  return (
    <div className="upcomingevents__container">
      <h3 className="upcomingevents__title">
        Upcoming {authUser.isAdmin ? "and Past " : ""}Events
      </h3>
      {authUser.isAdmin && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginBottom: 15,
          }}
        >
          <span style={{ fontWeight: 500 }}>Past events</span>
          <ToggleSwitch
            isActive={activeSwitch}
            func={() => setActiveSwitch(!activeSwitch)}
          />
          <span style={{ fontWeight: 500 }}>Upcoming events</span>
        </div>
      )}
      {events && events.length > 0 ? (
        events
          .sort((a, b) => {
            return new Date(a[1]?.startDate) - new Date(b[1]?.startDate);
          })
          .map((event, index) => {
            const day = moment(event[1]?.startDate).format("DD");
            const month = moment(event[1]?.startDate).format("MMMM");
            const startHour = moment(event[1]?.startDate).format("hh:mm");
            const endHour = moment(event[1]?.endDate).format("hh:mm");

            if (
              moment(event[1]?.startDate).add("1", "day") < Date.now() &&
              !authUser.isAdmin
            ) {
              return null;
            }

            if (
              authUser.isAdmin &&
              activeSwitch &&
              moment(event[1]?.startDate).add("1", "day") < Date.now()
            ) {
              return null;
            }

            if (
              authUser.isAdmin &&
              !activeSwitch &&
              moment(event[1]?.startDate).add("1", "day") >= Date.now()
            ) {
              return null;
            }

            // check if event is undefined
            if (!event) {
              return null;
            }

            return (
              <div
                onClick={() =>
                  authUser.isAdmin || authUser.isMentor
                    ? handleEventClick(event[0])
                    : {}
                }
                style={{ cursor: authUser.isAdmin ? "pointer" : "initial" }}
                key={index}
              >
                <Event
                  key={index}
                  authUser={authUser}
                  day={day}
                  event={event}
                  month={month}
                  startHour={startHour}
                  endHour={endHour}
                />
              </div>
            );
          })
      ) : authUser ? (
        authUser.hasCalendarAttachedToGoogle &&
        authUser.hasGoogleCalendarPermission ? (
          <NoEvents />
        ) : (
          <NeedPermisions
            category={
              !authUser.hasGoogleCalendarPermission
                ? "calendar-permissions"
                : "calendar-access"
            }
          />
        )
      ) : (
        <div>Loading..</div>
      )}
    </div>
  );
};

export default UpcomingEvents;
