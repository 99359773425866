import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { compose } from "recompose";

import { AuthUserContext, withAuthorization } from "src/components/Session";
import CourseContent from "./components/CourseContent";
import Notifications from "./components/Notifications";

import "./index.css";
import UpcomingEvents from "./components/UpcomingEvents";
import { withFirebase } from "src/components/Firebase";
import AdminClassrooms from "./components/AdminClassrooms";
import { useDispatch, useSelector } from "react-redux";
import { checkForUsersPermissions } from "src/store/users/usersActions";
import { getClassroomsForAdmin } from "src/store/classrooms/classroomsActions";
import {
  handleGoogleCalendarEvents,
  handleDbCalendarEvents,
} from "src/store/calendarEvents/calendarEventsActions";
import LegalNameAndPhoneModal from "../PhoneNumberModal";
import { getAllModules } from "src/store/modules/modulesActions";
import { moduleStarted } from "src/store/authUser/authUserActions";
import TermsAndConditions from "src/components/LSI/components/TermsAndConditions";
import { JoinedClassModal } from "src/components/PreAdjudication/components/diversionJoinedClassModal";
import PaymentPrompt from "../../../PreAdjudication/components/PaymentPrompt";
import ModulesLockedBanner from "src/components/PreAdjudication/components/modulesLockedBanner";
import { StripePaymentLinkButton } from "../../../StripePaymentButton";
import DiversionPaymentCard from "src/components/DiversionPaymentCard";
import Button from "src/components/shared/Button";

import { ClassroomChat } from "../../../ChatBubble";
import AdminTools from "./components/CourseContent/components/AdminTools";
import UserNav from "./components/UserNav";
import ProgramClosedCard from "./components/CourseContent/components/ProgramClosedCard";
import { setLocalStorageData } from "src/utils/localStorageUtils";
import UploadDocuments from "./components/UploadID";

export const Dashboard = ({ firebase }) => {
  const authUser = useContext(AuthUserContext);
  const dispatch = useDispatch();
  const [classData, setClassData] = useState(null);
  const [loading, setLoading] = useState(true);
  const { classrooms } = useSelector((state) => state.classrooms);
  const { modules } = useSelector((state) => state.modules);
  const events = useSelector((state) => state.events.items);
  const dbEvents = useSelector((state) => state.events.dbEvents);
  const history = useHistory();

  useEffect(() => {
    handleModules();
    dispatch(handleDbCalendarEvents(authUser?.classId, firebase));
    authUser.calendarId && dispatch(checkForUsersPermissions(authUser));
    dispatch(getClassroomsForAdmin(authUser));
  }, [authUser]);

  useEffect(() => {
    if (events && !authUser.hasCalendarAttachedToGoogle) {
      firebase.user(authUser.uid).update({
        hasCalendarAttachedToGoogle: true,
      });
    }
  }, [events]);

  const getClassroomDataFromCache = (classUID) => {
    const cacheKey = `classroomData-${classUID}`;
    const cachedClassroomData = localStorage.getItem(cacheKey);
    const cachedTime = localStorage.getItem(`${cacheKey}-time`);
    const now = new Date().getTime();
    const cacheTimeout = 10000;
    // const cacheTimeout = 3600000; // 1 hour in milliseconds

    if (
      cachedClassroomData &&
      cachedTime &&
      now - parseInt(cachedTime) < cacheTimeout
    ) {
      return JSON.parse(cachedClassroomData);
    }
    return null;
  };

  const fetchAndCacheClassroomData = async (classUID) => {
    const classData = await firebase.classroom(classUID).once("value");
    if (!classData.exists()) return null;

    const cacheKey = `classroomData-${classUID}`;
    setLocalStorageData(`classdata-${classUID}`, classData);
    localStorage.setItem(cacheKey, JSON.stringify(classData.val()));
    localStorage.setItem(`${cacheKey}-time`, new Date().getTime().toString());

    return classData.val();
  };

  const handleModules = async () => {
    setLoading(true);
    const classUID = authUser.classUID;
    let classroomData = getClassroomDataFromCache(classUID);

    if (!classroomData) {
      classroomData = await fetchAndCacheClassroomData(classUID);
    }

    if (classroomData) {
      dispatch(getAllModules(authUser, classroomData));
      setClassData(classroomData);
    } else {
      console.log("Dashboard 47 | no class data");
      setClassData(null);
    }
    setLoading(false);
  };

  const leaveClass = async () => {
    try {
      await firebase.user(authUser.uid).update({
        classroomId: null,
        classId: null,
        classUID: null,
        calendarId: null,
        hasCalendarAttachedToGoogle: false,
      });

      await firebase
        .userFromChatroom(authUser?.classUID, authUser?.uid)
        .remove();
    } catch (error) {
      console.log("Classroom 22 | error leaving classroom");
    }
  };

  const handleModuleStart = (lessonId) => {
    dispatch(moduleStarted(authUser, lessonId));
  };

  return (
    <div className="dashboard__outline">
      {!authUser?.termsAndConditionsAccepted && !authUser?.isMentor && (
        <TermsAndConditions
          user={authUser}
          firebase={firebase}
          navigate={history}
          termsAndConditionsAccepted={authUser.termsAndConditionsAccepted}
        />
      )}
      <div className="dashboard__container">
        <UserNav user={authUser} history={history} />
        {/* <div className="dashboard__topcontainer">
          {localStorage.getItem("courtName") &&
            !authUser?.termsAndConditionsAccepted && (
              <JoinedClassModal firebase={firebase} />
            )}
        </div> */}

        <AdminClassrooms
          authUser={authUser}
          classrooms={classrooms}
          isAdmin={authUser?.isAdmin}
          firebase={firebase}
        />

        {console.log("Dashboard 157 | classData", classData)}

        {classData && classData?.settings?.requiredStateId && (
          <UploadDocuments authUser={authUser} />
        )}
        {/* <Notifications authUser={authUser} /> */}

        {/* {!authUser.userPaidForDiversion &&
          classData?.isDiversion &&
          !classData.classroomClosed && (
            <DiversionPaymentCard authUser={authUser} />
          )} */}

        {classData && classData.classroomClosed && !authUser.isAdmin ? (
          <ProgramClosedCard />
        ) : (
          <>
            <div className="dashboard__contentcontainer">
              {/* {!authUser.userPaidForDiversion && classData?.isDiversion ? (
                <ModulesLockedBanner />
              ) : ( */}
              {/* <> */}
              <CourseContent
                authUser={authUser}
                firebase={firebase}
                classData={classData}
                setClassData={setClassData}
                classDataLoading={loading}
                handleModuleStart={handleModuleStart}
                modules={modules}
              />
              {/* </>
              )} */}
              {authUser && authUser.classId && (
                <UpcomingEvents authUser={authUser} events={events} />
              )}
            </div>
            {authUser.classId &&
              !authUser.isAdmin &&
              !authUser.isMentor &&
              classData && (
                <ClassroomChat
                  authUser={authUser}
                  firebase={firebase}
                  classId={authUser.classUID}
                  name={authUser.classId}
                  currentClassroomData={classData}
                />
              )}
            <LegalNameAndPhoneModal authUser={authUser} firebase={firebase} />
          </>
        )}
      </div>
      <AdminTools
        userId={authUser?.uid}
        leaveClass={leaveClass}
        isAdmin={authUser?.isAdmin}
        firebase={firebase}
        authUser={authUser}
      />
    </div>
  );
};

const condition = (authUser) => !!authUser;

export default compose(withAuthorization(condition), withFirebase)(Dashboard);
