import React from "react";
import "./index.css";
import Icon from "../../../../../shared/Icon";

const UserNav = ({ user, history }) => {
  return (
    <div className="usernav__container">
      <div className="usernav__profile">
        {user.photoURL && (
          <div className="usernav__image-icon">
            <img
              src={user.photoURL}
              width={50}
              height={50}
              style={{ borderRadius: 25 }}
            />
          </div>
        )}
        <div className="userinfo__container">
          <div className="usernav__name">
            {user.displayName || user.username}
          </div>
          <div className="usernav__role">
            {user.isAdmin && "Admin"} {user.isMentor && "Mentor"}{" "}
            {!user.isAdmin && !user.isMentor ? "Student" : ""}
          </div>
          <div className="usernav__class-id">
            {user.classId || "Please join a classroom"}
          </div>
        </div>
      </div>
      <div className="usernav__notifications-icon">
        <div className="usernav__settings-icon">
          <Icon
            name="settings-gray"
            style={{ color: "black" }}
            onClick={() => history.push("/settings")}
          />
        </div>
      </div>
    </div>
  );
};

export default UserNav;
