export const dataLength = (data) => {
  let length = JSON.stringify(data).length / 1000000;
  if (process.env.NODE_ENV === "development") {
    // console.log("dataLength.js 2 | 📈 length of data", length, " mb");
    if (length > 0.5) {
      console.log("dataLength.js 5 | 🔥🔥🔥 size warning", length, "mb", data);
    } else {
      console.log("dataLength.js 5 | 🟢🟢🟢 small size", length, "mb", data);
    }
  }
};
