import React, { useRef } from "react";
import "./index.css";

export const Button = ({
  title = "Button",
  onClick,
  type = "primary",
  loading = false,
  style = {},
  isAnimated = false,
}) => {
  const buttonRef = useRef(null);

  const buttonType = (type) => {
    const types = {
      primary: "rgba(217, 70, 69, 1)",
      disabled: "rgba(217, 70, 69, 0.5)",
      outline: "white",
    };
    return types[type];
  };

  const handleClick = () => {
    if (isAnimated && buttonRef.current) {
      buttonRef.current.classList.remove("button__animate");
      void buttonRef.current.offsetWidth; // Trigger reflow to restart the animation
      buttonRef.current.classList.add("button__animate");
    }

    if (onClick && type !== "disabled") {
      onClick();
    }
  };

  return (
    <button
      ref={buttonRef}
      className="button__container"
      disabled={type === "disabled"}
      style={{
        ...style,
        backgroundColor: buttonType(type),
        color: `${type === "outline" ? "rgba(217, 70, 69, 1)" : "white"}`,
        border: "2px solid rgba(217, 70, 69, 1)",
      }}
      onClick={handleClick}
    >
      {loading ? "Loading..." : title}
    </button>
  );
};

export default Button;
