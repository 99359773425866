import moment from "moment";
import React, { useState, useEffect } from "react";
import { EventModal } from "src/components/shared/EventModal";
import "./index.css";

import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  calendarEventsLoading,
  handleDbCalendarEvents,
} from "src/store/calendarEvents/calendarEventsActions";

const ClassEventsManager = ({
  calendarId,
  authUser,
  classData,
  firebase,
  classId,
  className,
}) => {
  const events = useSelector((state) => state.events.items);
  const dispatch = useDispatch();
  const eventsLoading = useSelector(
    (state) => state.events.calendarEventsLoading
  );

  console.log("class name and class id", className, classId);

  useEffect(() => {
    dispatch(handleDbCalendarEvents(className, firebase));
  }, []);

  if (!classData) {
    return <div>No class data</div>;
  }

  if (eventsLoading) {
    return <div>Loading...</div>;
  }

  if (!events) {
    return (
      <div>
        <div>{events ? "There are events" : "No events"}</div>
        {eventsLoading}

        {authUser.isAdmin && (
          <EventModal
            calendarId={calendarId}
            classId={classId}
            classData={classData}
            name={classData.className}
          />
        )}
      </div>
    );
  }

  return (
    <div>
      {authUser.isAdmin && (
        <EventModal
          calendarId={calendarId}
          classId={classId}
          classData={classData}
          name={classData.className}
        />
      )}

      <div className="classevents__container">
        {events &&
          events.map((event, index) => {
            return (
              <div key={index}>
                <Event
                  eventId={event[0]}
                  classId={classId}
                  event={event[1]}
                  authUser={authUser}
                />
              </div>
            );
          })}
      </div>
    </div>
  );
};
export const Event = ({ event, authUser, eventId }) => {
  const history = useHistory();
  const { title, attendees, description, startDate, endDate } = event;
  const day = moment(startDate).format("DD");
  const month = moment(startDate).format("MMMM");
  const startHour = moment(startDate).format("hh:mm");
  const endHour = moment(endDate).format("hh:mm");
  if (attendees?.length > 0) {
    if (!attendees.includes((attendee) => attendee.email === authUser.email)) {
      return null;
    }
  }

  return (
    <div
      className="upcomingevents__card__container"
      onClick={() => {
        history.push(`/event/${eventId}`);
      }}
    >
      <div className="upcomingevents__datecontainer">
        <div className="upcomingevents__day">{day}</div>
        <div className="upcomingevents__month">{month?.slice(0, 3)}</div>
      </div>
      <div className="upcomingevents__infocontainer">
        <div className="upcomingevents__name">{title}</div>
        <div className="upcomingevents__description">
          {description || event.location}
        </div>
      </div>
      <div className="upcomingevents__duedatecontainer">
        {startHour} - {endHour}
      </div>
    </div>
  );
};

export default ClassEventsManager;
