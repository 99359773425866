import React, { useEffect, useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../index.css";
import {
  AuthUserContext,
  withAuthentication,
  withAuthorization,
} from "../../Session";

import {
  setLoadingEvent,
  setLoadingUsers,
  setUsersList,
  setDatabaseEvent,
  setClassroom,
  setHasNoEventObject,
  setAttendanceMode,
  setEditEventOpen,
  setTestReminderOpen,
  setModuleName,
  setEventData,
  setReminderModalOpen,
} from "src/store/eventsManager/eventsManagerActions";

import { getCalendarEvent } from "src/api/googleCalendar";
import { toast } from "react-toastify";
import moment from "moment";
import EventReminderModal from "./EventReminderModal";
import EditEventModal from "./EditEventModal";
import TestEventReminderModal from "./TestEventReminderModal";
import ToggleSwitch from "src/components/shared/ToggleSwitch";
import { ClassroomChat } from "src/components/ChatBubble";
import EventHeader from "./EventHeader";
import EventDetails from "./EventDetails";
import AttendanceTable from "./AttendanceTable";
import {
  matchUserProperty,
  checkReminderConfirmed,
  checkStudentProgress,
} from "../eventManagerUtils";
import * as ROLES from "../../../constants/roles";
import { withFirebase } from "../../Firebase";
import { compose } from "recompose";
import { sendOneEmail } from "src/api/emails";
import { sendTextMessage } from "src/api/texts";

const AttendancePage = ({ firebase }) => {
  const dispatch = useDispatch();
  const {
    loadingEvent,
    loadingUsers,
    users,
    databaseEvent,
    classroom,
    hasNoEventObject,
    attendanceMode,
    editEventOpen,
    testReminderOpen,
    moduleName,
    eventData,
    reminderModalOpen,
  } = useSelector((state) => state.eventsManager);

  const authUser = useContext(AuthUserContext);
  const splittedUrl = window.location.href.split("/");
  const eventId = splittedUrl[splittedUrl.length - 1];
  console.log("calendar id", eventId);
  const isPastEvent =
    eventData.endDate && moment(eventData.endDate).diff(moment(), "days") < 0;

  useEffect(() => {
    dispatch(setLoadingEvent(true));
    firebase
      .getEventData(eventId)
      .once("value")
      .then(async (snapshot) => {
        const response = snapshot.val();
        if (response) {
          const databaseEvent = snapshot.val();
          console.log("databaseEvent", databaseEvent);
          dispatch(setDatabaseEvent(databaseEvent));
          console.log("got event data", databaseEvent);
          const classroom = await getClassroomByClassName(
            databaseEvent.classId
          );
          console.log("pasa classroom seach");
          dispatch(setClassroom(classroom));
          console.log("classroom", classroom);
          fetchEventData(eventId);
        }
      })
      .catch((error) => {
        console.log("errorrrrrr", error);
      });
  }, []);

  const getClassroomByClassName = async (className) => {
    let classroom;
    await firebase
      .classrooms()
      .orderByChild("className")
      .equalTo(className)
      .once("value")
      .then(async (classrooms) => {
        const response = classrooms.val();
        if (response) {
          classroom = Object.values(classrooms.val())[0];
          classroom.uid = Object.keys(classrooms.val())[0];
          console.log(
            "AttendancePage.js 93 | classroom",
            Object.keys(classrooms.val())[0]
          );
          console.log("if response", classroom);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });

    return classroom;
  };
  let totalRemindersConfirmed = 0;
  let totalUsersAttendance = 0;

  const handleTokenCreation = async (format, uid, isReminder) => {
    console.log("handling token creation", eventId);
    const token = await firebase.tokens().push({
      eventId: eventId,
      attendeeId: uid ? uid : reminderModalOpen.uid,
    });
    let path = `/attendees/${
      uid ? uid : reminderModalOpen.uid
    }/reminderConfirmed/${token.path.pieces_[1]}`;
    await firebase.getEventData(eventId).update({
      [path]: {
        format: isReminder ? format : "other",
        confirmed: false,
      },
    });
    return token.path.pieces_[1];
  };

  const handleSendOneMail = async (
    to,
    subject,
    message,
    emailRecipientName,
    uid,
    fromBulk = false
  ) => {
    if (!to) {
      toast.error("This user has no mail");
    } else {
      const isReminder = message.includes(
        "{https://myreded.com/event-confirmation/token}"
      );
      const token = await handleTokenCreation("mail", uid, isReminder);
      const link =
        process.env.NODE_ENV === "development"
          ? `http://localhost:3000/event-confirmation/${token}/${authUser.calendarId}`
          : `https://myreded.com/event-confirmation/${token}/${authUser.calendarId}`;
      let finalMessage = message.replace(
        "{https://myreded.com/event-confirmation/token}",
        link
      );

      sendOneEmail(to, subject, finalMessage, emailRecipientName)
        .then((res) => {
          if (!fromBulk) {
            toast.success(`Mail sent successfully`);
          }
        })
        .catch((err) => {
          toast.error(
            `Hubo un error al enviarle el correo a ${emailRecipientName}`
          );
        });
      dispatch(setReminderModalOpen(false));
    }
  };

  const handleSendOneSMS = async (to, message, uid, fromBulk = false) => {
    if (!to) {
      toast.error("This user has no phone");
    } else {
      const isReminder = message.includes(
        "{https://myreded.com/event-confirmation/token}"
      );
      const token = await handleTokenCreation("sms", uid, isReminder);
      const link =
        process.env.NODE_ENV === "development"
          ? `http://localhost:3000/event-confirmation/${token}/${authUser.eventId}`
          : `https://myreded.com//event-confirmation/${token}/${authUser.eventId}`;
      let finalMessage = message.replace(
        "{https://myreded.com/event-confirmation/token}",
        link
      );
      sendTextMessage(to, finalMessage)
        .then((res) => {
          if (!res.success) {
            toast.error(`There was an error sending this SMS`);
          }
          if (!fromBulk && res.success) {
            toast.success(`SMS sent successfully`);
          }
        })
        .catch((err) => {
          toast.error(`There was an error sending this SMS`);
        });
      dispatch(setReminderModalOpen(false));
    }
  };

  const handleBulkSend = async (
    mailSubject,
    mailChecked,
    mailMessage,
    smsChecked,
    smsMessage
  ) => {
    const toastId = toast.loading("Sending Reminders...");

    const promises = users.map(async (user) => {
      if (smsChecked) {
        await handleSendOneSMS(user.phoneNumber, smsMessage, user.uid, true);
      }
      if (mailChecked) {
        await handleSendOneMail(
          user.email,
          mailSubject,
          mailMessage,
          user.displayName,
          user.uid,
          true
        );
      }
    });

    await Promise.all(promises);

    toast.dismiss(toastId);
    toast.success("Reminders sent");
  };

  const createObjectfromExistingEvent = async () => {
    try {
      dispatch(setLoadingEvent(true));
      const googleEvent = await getCalendarEvent(
        eventData.googleCalendarId,
        eventId
      );
      const usersList = await getUsersWithClassId(authUser.classId);
      let attendeesObject = {};
      usersList.forEach((element) => {
        attendeesObject[element.uid] = {
          attendance: false,
          reminderConfirmed: {},
        };
      });

      const event = {
        title: googleEvent.title,
        ...(googleEvent.description && {
          description: googleEvent.description,
        }),
        ...(googleEvent.location && { location: googleEvent.location }),
        createdAt: googleEvent.created,
        creator: googleEvent.creator,
        attendees: attendeesObject,
        googleCalendarId: googleEvent.id,
        classId: authUser.classId,
        startDate: googleEvent?.start?.dateTime,
        endDate: googleEvent?.end?.dateTime,
      };

      await firebase.events().push(event);
      dispatch(setHasNoEventObject(false));
      fetchEventData(classroom.calendarId);
    } catch (error) {
      console.log("EventManager 325 | error creating event", error);
      dispatch(setLoadingEvent(false));
    }
  };

  const handleCellClick = (cell) => {
    let attended;
    eventData.attendeesList.forEach((e) => {
      if (e.uid === cell.row.original.uid) {
        attended = e.attendance;
      }
    });

    if (cell.column.Header === "Attendance" && attendanceMode) {
      let path = `/attendees/${cell.row.original.uid}/attendance`;
      firebase
        .getEventData(eventId)
        .update({
          [path]: !attended,
        })
        .then((res) => fetchEventData(eventId));
    }

    if (cell.column.Header === "Reminder" && !isPastEvent) {
      dispatch(
        setReminderModalOpen({
          type: "personal",
          title: cell.row.cells[0].value,
          uid: cell.row.original.uid,
          mail: matchUserProperty(users, cell.row.original.uid, "email"),
          phone: matchUserProperty(users, cell.row.original.uid, "phoneNumber"),
        })
      );
    }
  };

  const getUsersWithClassId = async (classId) => {
    let usersList;
    let filteredUsersList;
    console.log("getting users wiht class id  ", classId);
    await firebase
      .users()
      .orderByChild("classId")
      .equalTo(classId)
      .once("value", (snap) => {
        const usersObject = snap.val();
        if (usersObject) {
          usersList = Object.keys(usersObject).map((key) => ({
            ...usersObject[key],
            uid: key,
          }));
          dispatch(setLoadingUsers(false));
          filteredUsersList = usersList.filter(
            (e) => e.displayName && !e.isMentor && !e.isAdmin && !e.inactive
          );
          dispatch(setUsersList(filteredUsersList));
        }
      });
    return usersList;
  };

  const fetchEventData = async (googleCalendarId) => {
    console.log("llega a fetchevetn data");
    // const googleEvent = await getCalendarEvent(googleCalendarId, eventId);
    await firebase.getEventData(eventId).once("value", (snapshot) => {
      let responseData = snapshot.val();
      if (responseData === null) {
        dispatch(setHasNoEventObject(true));
        dispatch(setLoadingEvent(false));
        return;
      }
      let eventData = snapshot.val();
      let attendeesList = eventData.attendees
        ? Object.keys(eventData.attendees).map((key) => ({
            attendance: eventData.attendees[key].attendance,
            reminderConfirmed: eventData.attendees[key].reminderConfirmed,
            uid: key,
          }))
        : [];
      dispatch(setLoadingEvent(false));
      dispatch(setLoadingUsers(true));
      getUsersWithClassId(eventData.classId);
      dispatch(
        setEventData({
          eventName: eventData.title,
          startDate: eventData.startDate,
          endDate: eventData.endDate,
          description: eventData.description,
          location: eventData.location,
          classId: eventData.classId,
          eventId: Object.keys(responseData)[0],
          module: eventData.module,
          attendeesList: attendeesList,
        })
      );

      fetchModule(eventData.module);
    });
  };

  const fetchModule = async (moduleId) => {
    firebase.getLesson(moduleId).once("value", (snap) => {
      const module = snap.val();
      if (module) {
        dispatch(setModuleName(module.name));
      }
    });
  };

  return (
    <div className="event__manager__container">
      {reminderModalOpen.type && (
        <EventReminderModal
          title={reminderModalOpen.title}
          type={reminderModalOpen.type}
          mail={reminderModalOpen.mail}
          phone={reminderModalOpen.phone}
          closeModal={() => dispatch(setReminderModalOpen({}))}
          handleSendOneMail={handleSendOneMail}
          handleSendOneSMS={handleSendOneSMS}
          handleBulkSend={handleBulkSend}
        />
      )}
      {editEventOpen && (
        <EditEventModal
          createPrivateEventOpen={editEventOpen}
          setCreatePrivateEventOpen={(isOpen) =>
            dispatch(setEditEventOpen(isOpen))
          }
          eventName={eventData.eventName}
          eventAddress={eventData.eventAddress}
          eventDescription={eventData.description}
          eventLocation={eventData.location}
          eventStartDate={eventData.startDate}
          eventEndDate={eventData.endDate}
          eventId={eventId}
          eventModule={eventData.module}
          googleCalendarEventId={eventId}
          calendarId={authUser.calendarId}
          classId={eventData.classId}
          fetchEventData={fetchEventData}
          classroom={classroom}
        />
      )}

      {testReminderOpen && (
        <TestEventReminderModal
          closeModal={() => dispatch(setTestReminderOpen(false))}
          authUser={authUser}
          eventId={eventData.eventId}
        />
      )}

      {loadingEvent ? (
        <div>loading..</div>
      ) : hasNoEventObject ? (
        <div style={{ margin: "auto" }}>
          <div>
            This event has no manager associated to it, do you want to create
            one?
          </div>
          <button
            onClick={createObjectfromExistingEvent}
            className="mentorprivateeventbuttons__button"
          >
            {!loadingEvent ? "Create" : "Creating..."}
          </button>
        </div>
      ) : (
        <>
          <EventHeader
            eventData={eventData}
            isPastEvent={isPastEvent}
            setReminderModalOpen={(modalData) =>
              dispatch(setReminderModalOpen(modalData))
            }
            setTestReminderOpen={(isOpen) =>
              dispatch(setTestReminderOpen(isOpen))
            }
            setEditEventOpen={(isOpen) => dispatch(setEditEventOpen(isOpen))}
          />
          <EventDetails
            eventData={eventData}
            moduleName={moduleName}
            setEditEventOpen={(isOpen) => dispatch(setEditEventOpen(isOpen))}
          />
          <div className="event__labels">
            <div>
              <span>Email</span>
              <div className="blue__ball__legend" />
            </div>
            <div>
              <span>Text</span>
              <span className="orange__ball__legend" />
            </div>
            <div>
              <span>Push</span>
              <span className="red__ball__legend" />
            </div>
            <div>
              <span>Other</span>
              <span className="yellow__ball__legend" />
            </div>
            <div>
              <span>Confirmed</span>
              <span>
                <img
                  src={"/assets/images/green_check_icon.svg"}
                  className="evaluation_icons_glosary"
                  alt="green check icon"
                />
              </span>
            </div>
            <div>
              <span>Rejected</span>
              <span>
                <img
                  src={"/assets/images/red_cross_icon.svg"}
                  className="evaluation_icons_glosary"
                  alt="red cross icon"
                />
              </span>
            </div>
          </div>
          <div className="attendance__page__attendance__mode">
            Attendance mode{" "}
            <ToggleSwitch
              isActive={attendanceMode}
              func={(mode) => dispatch(setAttendanceMode(mode))}
            />
          </div>
          <AttendanceTable
            eventData={eventData}
            users={users}
            handleCellClick={handleCellClick}
            attendanceMode={attendanceMode}
            totalRemindersConfirmed={totalRemindersConfirmed}
            totalUsersAttendance={totalUsersAttendance}
          />
        </>
      )}
      {classroom && (
        <ClassroomChat
          authUser={authUser}
          firebase={firebase}
          currentClassroomData={classroom}
          classId={classroom.uid}
        />
      )}
    </div>
  );
};

const condition = (authUser) =>
  authUser && (authUser.roles[ROLES.ADMIN] || authUser.isMentor);

export default compose(
  withAuthorization(condition),
  withAuthentication,
  withFirebase
)(AttendancePage);
