/* eslint-disable */
import React, { Fragment } from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
  Image,
} from "@react-pdf/renderer";

const styles = StyleSheet.create({
  documentStyle: {
    backgroundColor: "#ffffff",
    color: "#000000",
  },

  page: {
    backgroundColor: "#ffffff",
    fontSize: 10,
    padding: "30px 40px",
    color: "#000000",
  },

  pdf_page_title: {
    fontWeight: "bold",
    fontSize: 18,
  },

  pdf_page_subtitle: {
    fontWeight: "bold",
    fontSize: 14,
  },

  pdf_normal_space: {
    marginTop: 10,
  },

  pdf_big_space: {
    marginTop: 20,
  },

  pdf_bold: {
    fontWeight: "bold",
    color: "#000000",
  },

  redTitle: {
    fontWeight: "bold",
    fontSize: 14,
    color: "#FFFFFF",
  },

  redContainer: {
    marginTop: 20,
    marginBottom: 20,
    backgroundColor: "#d94645",
    width: 235,
    height: 40,
    padding: 10,
  },
});

function DiversionTermsAndConditions({
  base64,
  documentTitle,
  terms,
  acceptedTerms,
  userName,
}) {
  return (
    <Document title={documentTitle} style={styles.documentStyle}>
      <Page size="A4" orientation="portrait">
        <View style={styles.page}>
          <View style={styles.pdf_page_subtitle}>
            <Text>
              Rehabilitation Enables Dreams, Inc. (dba RED) Terms and Conditions
            </Text>
          </View>
          <View style={styles.pdf_big_space}>
            <Text>{terms}</Text>
          </View>
          {/* <View style={styles.pdf_big_space}>
              <Text>{acceptedTerms}</Text>
            </View> */}
          <View style={styles.pdf_big_space}>
            <Text>{userName}</Text>
          </View>
          <Image source={base64} style={{ width: 250, height: "auto" }} />
        </View>
      </Page>
    </Document>
  );
}

export default DiversionTermsAndConditions;
