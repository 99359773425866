export const LANDING = "/";
export const SIGN_UP = "/signup";
export const SIGN_IN = "/signin";
export const HOME = "/home";
export const ACCOUNT = "/account";
export const ADMIN = "/admin";
export const PASSWORD_FORGET = "/pw-forget";
export const LSI = "/LSI";
export const ADD_LESSON_PAGE = "/addLesson";
export const EDIT_LESSON_PAGE = "/edit/:lessonId";
export const VIEW_LESSON_PAGE = "/lesson/:lessonId";
export const VIEW_EVENT_PAGE = "/event/:eventId";
export const VIEW_EVENT_CONFIRMATION_PAGE = "/event-confirmation/:token";
export const VIEW_CLASSROOM = "/viewClassroom/:classId";
export const CHAT_SCREEN = "/chat/:chatId";
export const VIEW_USER_PAGE = "/viewUser/:userId";
export const PRIVACY_POLICY = "/privacyPolicy";
export const CALENDAR = "/calendar";
export const LESSONS_ANALYTICS = "/lessonsAnalytics";
export const QUEST_EQ_TEST = "/eqTest";
export const CERTIFICATES_CREATE = "/certificates";
export const FINAL_ASSESSMENT = "/finalAssessment";
export const CLASSROOM = "/classroom";
export const DIVERSION = "/diversion";
export const ISSUES = "/issues";
export const CERFITICATES = "/certificates";
export const ANALYTICS = "/lessonAnalytics";
export const TASKLIST = "/taskList";
export const REVIEWS = "/moduleReviews";
export const SUPPORT = "/support";
export const EIGYST = "/eqAssessment";
export const EIResults = "/eqResults";
export const BudgetingGame = "/budgetingGame";
export const FinanceLessons = "/financeLessons";
export const CREDIT_SIMULATOR = "/creditSimulator";
export const LSI_ASSESSMENT = "/LSIAssessment";
export const EDIT_LSI = "/editLSI";
export const USER_SETTINGS = "/settings";
export const VALIDATE = "/validate";
export const CLASSROOM_CHATS = "/classroomChats";
