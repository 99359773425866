/* eslint-disable */
import React from 'react';
import { Image, Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';

// Define styles
const styles = StyleSheet.create({
  documentStyle: {
    fontFamily: "Poppins",
    backgroundColor: "#ffffff",
    color: "#000000",
  },
  page: {
    backgroundColor: "#ffffff",
    fontFamily: "Poppins",
    fontSize: 10,
    padding: "30px 40px",
    color: "#000000",
  },
  section: {
    marginBottom: 20
  },
  header: {
    fontFamily: "Poppins",
    fontWeight: "bold",
    fontSize: 18,
    paddingBottom: 16,
  },
  subHeader: {
    fontSize: 14,
    marginBottom: 5,
    fontWeight: "bold"
  },
  text: {
    fontSize: 12
  },
  table: {
    display: "table",
    width: "auto"
  },
  tableRow: {
    flexDirection: "row",
    width: "100%",
    marginBottom: 2,
  },
  tableCellHeader: {
    flex: 1,
    fontSize: 10,
    fontWeight: "bold",
    padding: 3,
    borderBottomWidth: 1,
    borderBottomColor: 'grey',
    borderBottomStyle: 'solid',
  },
  tableCell: {
    flex: 1,
    fontSize: 10,
    padding: 3,
    borderBottomWidth: 1,
    borderBottomColor: 'grey',
    borderBottomStyle: 'solid',
  },
  watermark: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: 200,
    height: 200,
    marginLeft: -100, // half of the width
    marginTop: -100,  // half of the height
    opacity: 0.2,
  },
  redTitle: {
    fontWeight: 'bold',
    fontSize: 14,
    color: '#FFFFFF'
  },
  redContainer: {
    marginTop: 20,
    marginBottom: 20,
    backgroundColor: '#d94645',
    width: 600,
    height: 40,
    padding: 10
  }
});

const AttendanceReport = ({ eventAttendance, attendeeSummary, documentTitle }) => (
  <Document title={documentTitle} style={styles.documentStyle}>
    <Page style={styles.page}>
      <View style={styles.section}>
        <Text style={styles.header}>Event Attendance Report</Text>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <Text style={styles.tableCellHeader}>Event</Text>
            <Text style={styles.tableCellHeader}>Total Attendees</Text>
            <Text style={styles.tableCellHeader}>Confirmed Attendees</Text>
            <Text style={styles.tableCellHeader}>Confirmed to Attended Ratio (%)</Text>
          </View>
          {eventAttendance.map((event, index) => (
            <View wrap={false} key={index} style={styles.tableRow}>
              <Text style={styles.tableCell}>{event.title}</Text>
              <Text style={styles.tableCell}>{event.totalAttendees}</Text>
              <Text style={styles.tableCell}>{event.confirmedAttendees}</Text>
              <Text style={styles.tableCell}>{Math.round((event.confirmedAttendees / event.totalAttendees) * 100)}</Text>
            </View>
          ))}
        </View>
      </View>
      <View style={styles.section}>
        <Text style={styles.header}>Attendee Summary</Text>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <Text style={[styles.tableCellHeader, { flex: 2 }]}>Name</Text>
            <Text style={styles.tableCellHeader}>Attended</Text>
            <Text style={styles.tableCellHeader}>% Attended</Text>
            <Text style={styles.tableCellHeader}>Confirmed</Text>
            <Text style={styles.tableCellHeader}>% Confirmed</Text>
          </View>
          {Object.entries(attendeeSummary).map(([id, data]) => (
            <View wrap={false} key={id} style={styles.tableRow}>
              <Text style={[styles.tableCell, { flex: 2 }]}>{id}</Text>
              <Text style={styles.tableCell}>{data.attended}</Text>
              <Text style={styles.tableCell}>{((data.attended / data.totalEvents) * 100).toFixed(2)}</Text>
              <Text style={styles.tableCell}>{data.confirmed}</Text>
              <Text style={styles.tableCell}>{((data.confirmed / data.totalEvents) * 100).toFixed(2)}</Text>
            </View>
          ))}
        </View>
      </View>
    </Page>
  </Document>
);

export default AttendanceReport;
