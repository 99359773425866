import React, { useCallback } from "react";
import StepsDiv from "./StepsDiv";
import "./JoinStepsSection.css";

import { useDispatch } from "react-redux";
import { toggleSignupModal } from "../../../store/authUser/authUserActions";

const JoinStepsSection = () => {
  const dispatch = useDispatch();
  const onButtonContainerClick = useCallback(() => {
    dispatch(toggleSignupModal(true));
    console.log("JoinStepsSection.js 11 | openning login modal");
  }, []);

  return (
    <div className="features4">
      <div className="headline-subhead3">
        <div className="enroll-now">Overview</div>
        <div className="red-ptd-is1">
          RED PTD is a 6-month diversion program for individuals charged with a
          first-time non-violent misdemeanor or felony offense, primarily drug
          and theft offenses. The program is designed to provide an alternative
          to traditional criminal justice system processes, with a focus on
          rehabilitation, education, and community reintegration.
        </div>
      </div>
      <div className="cards1">
        <div className="div1">
          <StepsDiv actionText="01" text={"Register"} />
          <StepsDiv actionText="02" text={"Complete Modules"} />
        </div>
        <div className="div1">
          <div className="steps7">
            <div className="div-step">3</div>
            <div className="attend-compliance-hearing-wrapper">
              <div className="attend-compliance-hearing">
                Attend Compliance Hearing
              </div>
            </div>
          </div>
          <div className="steps7">
            <div className="div-step">4</div>
            <div className="attend-compliance-hearing-wrapper">
              <div className="attend-compliance-hearing">
                <p className="case-dismissed">Case Dismissed,</p>
                <p className="case-dismissed">Record Restricted</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button
        onClick={() => {
          //
        }}
        className="navigation-menu1"
      >
        <div className="joinstepsbutton">
          <img className="arrowright-icon10" alt="" src="/arrowright1.svg" />
          <div className="label14">Scroll to top</div>
          <img className="arrowright-icon10" alt="" src="/arrowright1.svg" />
        </div>
      </button>
    </div>
  );
};

export default JoinStepsSection;
