import React from "react";
import Icon from "src/components/shared/Icon";

import "./index.css";

const FinishedCourse = ({
  isAdmin,
  allModulesComplete,
  finalAssessmentComplete,
}) => {
  if (isAdmin) return null;
  if (allModulesComplete && finalAssessmentComplete) {
    return (
      <div className="finishedcourse__container">
        <h1>Congratulations!</h1>
        <h5>You have successfully finished the Stop Recidivism Course!</h5>
        <Icon name="complete" />
      </div>
    );
  }

  return null;
};

export default FinishedCourse;
