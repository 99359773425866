import React from "react";
import "./FeaturesContainerRow.css";
const FeaturesContainerRow = ({
  moduleQRCode,
  moduleDescription,
  classroomQRCode,
  classroomDescription,
}) => {
  return (
    <div className="features1">
      <div className="card-info3">
        <img className="image-1-icon" alt="" src="/image-1@2x.png" />
        <div className="simple-sign-up-with">{moduleDescription}</div>
      </div>
      <div className="card-info3">
        <img className="image-1-icon" alt="" src="/image-2@2x.png" />
        <div className="two-classrooms-drug">{classroomDescription}</div>
      </div>
    </div>
  );
};

export default FeaturesContainerRow;
