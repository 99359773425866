import React from "react";
import SectionOverviewInfo from "./components/SectionOverviewInfo";
import MetricsSection from "./components/MetricsSection";
import { useSelector } from "react-redux";
import FeaturesSection from "./components/FeaturesSection";
import LoginModal from "../../components/LoginModal";
import JoinStepsSection from "./components/JoinStepsSection";
import VideoSection from "./components/VideoSection";

import "./DiversionLanding.css";

import CurriculumOutline from "./components/CurriculumOutline";
import Testimonials from "./components/Testimonials";

import { DiversionHeroNew } from "./components/DiversionHeroNew";
import LandingFooter from "../LandingFooter";
import LandingFeaturesCarousel from "./components/FeaturesCarousel/FeaturesCarousel";

const LandingPageWithVideoDesk = () => {
  const { openLoginModal } = useSelector((state) => state.authUser);

  return (
    <div className="landing-page-diversion">
      {openLoginModal && <LoginModal fromDiversion isOpen={openLoginModal} />}
      <DiversionHeroNew />
      <SectionOverviewInfo />
      <LandingFeaturesCarousel />
      <MetricsSection />
      <VideoSection />
      <FeaturesSection />
      <CurriculumOutline />
      <Testimonials />
      <JoinStepsSection />
      <LandingFooter />
    </div>
  );
};

export default LandingPageWithVideoDesk;
