import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AuthUserContext } from "src/components/Session";
import { setLoginType } from "src/store/loginType/loginTypeActions";
import LandingFooter from "../../LandingFooter";
import ActionContainer from "./ActionContainer";
import CurriculumContainer from "./CurriculumContainer";
import FeaturesContainer from "./FeaturesContainer";
import LearnMoreContainer from "./LearnMoreContainer";
import { NewActionContainer } from "./NewActionContainer";
import PlatformDescriptionContainer from "./PlatformDescriptionContainer";
import StatisticsContainer from "./StatisticsContainer";
import VideoContainer from "./VideoContainer";
import LoginModal from "../../LoginModal";

import Testimonials from "../../DiversionLandingPage/components/Testimonials";
import Footer from "./Footer";
import LandingFeaturesCarousel from "src/components/DiversionLandingPage/components/FeaturesCarousel/FeaturesCarousel";
import MetricsSection from "src/components/DiversionLandingPage/components/MetricsSection";

const LandingPage = ({ history }) => {
  const authUser = useContext(AuthUserContext);
  const dispatch = useDispatch();
  const { openLoginModal } = useSelector((state) => state.authUser);
  console.log("openLoginModal", openLoginModal);
  const [joinAs, setJoinAs] = useState("student");

  const handleJoinAs = (type) => {
    console.log("ActionContainer 13 | handling join as", type);
    dispatch(setLoginType(type));
    localStorage.setItem("loginType", type);
    setJoinAs(type);
  };

  const queryParams = new URLSearchParams(window.location.search);
  const classId = queryParams.get("classId");

  console.log("Landing.js 38 | classId", classId);

  return (
    <div className="landing-page-container">
      <div
        className="landing-page-content"
        style={{ marginTop: authUser ? 30 : 0 }}
      >
        {/* <ActionContainer
          handleJoinAs={handleJoinAs}
          joinAs={joinAs}
          authUser={authUser}
          history={history}
        /> */}
        {openLoginModal && (
          <LoginModal isOpen={openLoginModal} classId={classId} />
        )}
        <NewActionContainer
          handleJoinAs={handleJoinAs}
          joinAs={joinAs}
          authUser={authUser}
          history={history}
          classId={classId}
        />
        {/* <VideoContainer /> */}
        <LandingFeaturesCarousel />
        {/* <StatisticsContainer /> */}
        <div style={{ marginTop: 60 }}>
          <MetricsSection />
        </div>
        {/* <PlatformDescriptionContainer /> */}
        <CurriculumContainer />
        {/* <FeaturesContainer /> */}
        <Testimonials />

        {/* <CustomerReviewsContainer /> */}
        {/* <ContactUsContainer /> */}
        {/* <LearnMoreContainer /> */}
      </div>
      {/* <Footer /> */}
      <Footer />
    </div>
  );
};

export default LandingPage;
