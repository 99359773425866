import { getCalendarEvents } from "src/api/googleCalendar";
import { errorNotification, openNotification } from "src/utils/notifications";

export function handleDbCalendarEvents(className, firebase) {
  return async function (dispatch) {
    dispatch(calendarEventsLoading(true));
    try {
      let events = [];
      if (!className) {
        console.log("no class name");
        dispatch(setCalendarEvents(null));
      } else {
        events = await getEventsFromDb(className, firebase);
        dispatch(setCalendarEvents(Object.entries(events)));
        dispatch(calendarEventsLoading(false));
      }
      dispatch(errorGettingCalendarEvents(events.error));
    } catch (error) {
      dispatch(calendarEventsLoading(false));
      console.log(
        "calendarEventsActions.js 11 | error",
        className,
        error.message
      );
    }
  };
}

const getEventsFromDb = async (classId, firebase) => {
  try {
    const events = await firebase
      .queryData("events", "classId", classId)
      .once("value");

    console.log("got events", events.val());

    return events.val();
  } catch (error) {
    return {};
  }
};

export function handleGoogleCalendarEvents(calendarId) {
  return async function (dispatch) {
    try {
      let events = [];
      if (!calendarId) {
        console.log("No calendar id");

        dispatch(setCalendarEvents(null));
      } else {
        events = await getCalendarEvents(calendarId);
        dispatch(setCalendarEvents(events.items));
      }
      dispatch(errorGettingCalendarEvents(events.error));
    } catch (error) {
      console.log("calendarEventsActions.js 11 | error", error.message);
    }
  };
}

export const setCurrentCalendarId = (calendarId) => {
  return { type: "currentCalendarId/set", payload: calendarId };
};

export const handleCreateCalendarAndDbEvent = async (
  firebase,
  data,
  usersList,
  currentClassData
) => {
  return async function (dispatch) {
    try {
      let attendeesObject = {};
      usersList.forEach((element) => {
        attendeesObject[element.uid] = {
          attendance: false,
          reminderConfirmed: {
            // uid : {type: phone/mail, confirmed: true/false, token:id}
          },
        };
      });

      const eventObject = {
        title: data.summary,
        createdAt: data.created,
        creator: data.creator,
        attendees: attendeesObject,
        googleCalendarId: data.id,
        classId: currentClassData.className,
        startDate: data.start.dateTime,
        endDate: data.end.dateTime,
        ...(module && { module: module }),
        ...(data.location && { location: data.location }),
        ...(data.description && { description: data.description }),
      };
      // Send the event object after creation
      await firebase.events().push(eventObject);
      openNotification("Success!", "Event created successfully in database!");
      dispatch(handleGoogleCalendarEvents(currentClassData.calendarId));
    } catch (error) {
      console.log("EventManager 59 | error creating event", error);
      dispatch(handleGoogleCalendarEvents(currentClassData.calendarId));
      errorNotification("Error!", "Error creating event in database!");
    }
  };
};

export const setCalendarEvents = (events) => {
  return { type: "calendarEvents/set", payload: events };
};

export const errorGettingCalendarEvents = (error) => {
  return { type: "calendarEvents/error", payload: error };
};

export const calendarEventsLoading = (payload) => {
  console.log("loading" + payload);
  return { type: "calendarEvents/loading", payload: payload };
};
