import React from "react";

import SwitchItemOrderButtons from "../shared/SwitchItemOrderButtons";

import "./index.css";

export const Quiz = ({
  editSection,
  item,
  index,
  removeContentAtIndex,
  _moveSectionContentDown,
  _moveSectionContentUp,
}) => {
  return (
    <div className="quiz-edit" key={index}>
      <h3 className="editcontent__title">Quiz</h3>
      <div className="quiz-edit-question">
        {/* <h5 className="quiz-question-title">Question</h5> */}
        <input
          className="imageinput__text"
          value={item.question}
          placeholder="Question"
          name="question"
          onChange={(e) => editSection(e, index, "quiz")}
        />
      </div>
      <div className="editcontent__title">Options</div>
      {/* TODO refactor this stuff */}
      {item.options.map((option, optionIndex) => (
        <div key={optionIndex / 2} className="quizedit__answer">
          <div
            type="radio"
            className={
              item.answer === option && item.answer !== ""
                ? "quizselected__option"
                : "quiznotselected__option"
            }
            // onClick={() => handleEdit(itemIndex, option)}
            key={index}
            onClick={(e) =>
              editSection(
                { target: { name: "answer", value: option } },
                index,
                "quiz"
              )
            }
          />

          <input
            className="optioninput__text"
            key={optionIndex}
            placeholder={`Option ${optionIndex + 1} ${
              optionIndex === 4 ? "(Optional)" : ""
            }`}
            value={option}
            name="option"
            onChange={(e) => editSection(e, index, "quiz", optionIndex)}
          />
        </div>
      ))}

      {/* <div className="quiz-edit-question">
        <h5 className="correct-answer-title">Correct Answer</h5>
        <input
          className="single-input"
          value={item.answer}
          placeholder="Correct Answer"
          name="answer"
          onChange={(e) => editSection(e, index, "quiz")}
        />
      </div>
      <div>
        <h5>Category</h5>
        <Input
          className="single-input"
          placeholder="Category (Optional)"
          value={item.category}
          name="category"
          onChange={(e) => editSection(e, index, "quiz")}
        />
      </div> */}
      <SwitchItemOrderButtons
        index={index}
        _moveSectionContentDown={_moveSectionContentDown}
        _moveSectionContentUp={_moveSectionContentUp}
      />
      <button
        className="btn btn-danger"
        onClick={(e) => removeContentAtIndex(e, index)}
      >
        Delete Quiz
      </button>
    </div>
  );
};
